import React from "react";

import css from "./index.sass";
import SearchBar from "./SearchBar";
import FilterSwitch from "./FilterSwitch";
import { formatStatusText } from "~brokerage/constants/appointments";
import Loader from "~brokerage/components/shared/Loader";
import { Dropdown } from "~brokerage/components/shared/Form";
import { reasonText } from "~brokerage/constants/appointments";
import BuyerAutocomplete from "~brokerage/components/modals/ModalCreateShowing/steps/SendRequest/BuyerSection/AddBuyer/BuyerAutocomplete";
import Buyer from "./Buyer";

const FilterSection = ({ loading, filters, flipFilterSwitch, setFilter }) => {
  const {
    isListingAgent,
    isShowingAgent,
    showPending,
    showApproved,
    showDeclined,
    showCancelled,
    showExpired,
    addressSearchTerm,
    showOthers,
    appointmentReasonFilter,
    buyer
  } = filters;

  const appointmentReasons = Object.entries(reasonText).map(
    ([value, label]) => ({
      label,
      value
    })
  );

  if (loading) {
    return <Loader active />;
  }

  return (
    <div>
      <h1 className={css.filter}> Filters </h1>
      <div className={css.filterSection}>
        <SearchBar
          setSearchTerm={term => setFilter("addressSearchTerm", term)}
          value={addressSearchTerm}
          placeholderText="Search by Address"
        />
        <div style={{ height: "10px" }} /> {/*Spacer*/}
        <SearchBar
          setSearchTerm={term => setFilter("agentSearchTerm", term)}
          placeholderText="Search by Agent"
        />
      </div>
      <div className={css.filterSection}>
        <p className={css.filterHeader}>
          <span className={css.filterLabelHeaderSpan}>Appointment</span> Type
        </p>
        <Dropdown
          name="type"
          options={[{ label: "All", value: "all" }, ...appointmentReasons]}
          selected={appointmentReasonFilter}
          onChange={({ type }) => setFilter("appointmentReasonFilter", type)}
          variant="outline2Block"
        />
      </div>
      <div className={css.filterSection}>
        <p className={css.filterHeader}>
          <span className={css.filterLabelHeaderSpan}>Buyer</span> Name
        </p>
        {!buyer ? (
          <BuyerAutocomplete
            buyers={[]}
            onBuyerAdd={({ id, label: name }) =>
              setFilter("buyer", { id, name })
            }
            newBuyerOption={false}
          />
        ) : (
          <Buyer
            name={buyer.name}
            onRemoveClick={() => setFilter("buyer", null)}
          />
        )}
      </div>
      <div className={css.filterSection}>
        <p className={css.filterHeader}>
          <span className={css.filterLabelHeaderSpan}>Appointment</span> Role
        </p>
        <FilterSwitch
          value={isListingAgent}
          onClick={() => flipFilterSwitch("isListingAgent")}
          text="My Listings"
        />

        <FilterSwitch
          value={isShowingAgent}
          onClick={() => flipFilterSwitch("isShowingAgent")}
          text="My Showings"
        />
      </div>
      <div className={css.filterSection}>
        <p className={css.filterHeader}>
          <span className={css.filterLabelHeaderSpan}>Appointment</span> Status
        </p>
        <FilterSwitch
          value={showPending}
          onClick={() => flipFilterSwitch("showPending")}
          text={formatStatusText("pending")}
          status="pending"
        />

        <FilterSwitch
          value={showApproved}
          onClick={() => flipFilterSwitch("showApproved")}
          text={formatStatusText("approved")}
          status="approved"
        />

        <FilterSwitch
          value={showCancelled}
          onClick={() => flipFilterSwitch("showCancelled")}
          text={formatStatusText("cancelled")}
          status="cancelled"
        />

        <FilterSwitch
          value={showDeclined}
          onClick={() => flipFilterSwitch("showDeclined")}
          text={formatStatusText("declined")}
          status="declined"
        />

        <FilterSwitch
          value={showExpired}
          onClick={() => flipFilterSwitch("showExpired")}
          text={formatStatusText("pending", true)}
          status="pending"
        />
        <FilterSwitch
          value={showOthers}
          onClick={() => flipFilterSwitch("showOthers")}
          text="Others"
          status="stop"
        />
      </div>
    </div>
  );
};

export default FilterSection;
