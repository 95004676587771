import React from "react";
import css from "./FilterSwitch.sass";
import {
  statusIcon,
  statusColor,
  pastStatusText
} from "~brokerage/app/constants/appointments";
import { RiCheckboxBlankLine, RiCheckboxFill } from "@remixicon/react";

const FilterSwitch = ({ value, onClick, text, status }) => {
  const color = status && statusColor(status, text === pastStatusText[status]);
  const Icon = status && statusIcon(status);

  return (
    <div onClick={onClick} className={css.filterContent}>
      {value ? (
        <RiCheckboxFill className={css.icon} />
      ) : (
        <RiCheckboxBlankLine className={css.icon} />
      )}
      <div className={css.status} style={{ color }}>
        {status && (
          <div className={css.IconContainer} style={{ backgroundColor: color }}>
            <Icon color="#fff" size={14} />
          </div>
        )}
        {text}
      </div>
    </div>
  );
};

export default FilterSwitch;
